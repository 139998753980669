.tuLogo{
  height: 120px;
}

  @media (max-width: 575.98px) {
    .tuLogo {
        height: 100%;
        width: 90%;
      }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .tuLogo {
        height: 70px;
      }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .tuLogo {
        height: 90px;
      }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .tuLogo {
        height: 90px;
      }
  }