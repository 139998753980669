

  @media (max-width: 575.98px) {
    .mail {
        height: 35vh;
        width: 60vw;
        
      }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .mail {
        height: 35vh;
        width: 60vw;
        
      }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .mail {
        height: 35vh;
        width: 60vw;
        
      }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .mail {
        height: 35vh;
        width: 60vw;
        
      }
  }