.takePic {
  width: 100%;
}

@media (min-width: 576px) {
  .takePic {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .takePic {
    width: 30%;
  }
}