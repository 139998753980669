

  @media (max-width: 575.98px) {
    .takeSelfie {
        height: 25vh;
        width: 80vw;
        /* width: 20vw; */
        
      }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .takeSelfie {
        height: 15vh;
        width: 72vw;
        
      }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .takeSelfie {
        height: 10vh;
        
      }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .takeSelfie {
        height: 10vh;
        
      }
  }